import React from 'react';

// teams.tsx
export interface Team {
    id: number;
    name: string;
    emblem: string;
    emblemUrl: string;
    played: number;
    won: number;
    drawn: number;
    lost: number;
    goalsFor: number;
    goalsAgainst: number;
    points: number;
    emblemElement(): React.ReactNode;
}

// Data accessor to get the teams from the API
// It keeps the data in sync with the API
export class TeamsDataAccessor {
    private teamInfo: Team[] = [];
    private isFetching: boolean = false;

    private baseUrl = process.env.BASE_URL;
    private apiUrl = `${this.baseUrl}/api/v1/teams`;

    constructor() {
        this.fetchTeams();
    }

    private async fetchTeams(): Promise<void> {
        this.isFetching = true;
        try {
            const response = await fetch(this.apiUrl);
            this.teamInfo = await response.json();
            this.isFetching = false;
            // Optionally notify observers or update the UI
        } catch (error) {
            this.isFetching = false;
            throw new Error('Failed to fetch teams');
        }
    }

    // get the teams from the API and keep them in sync with the API
    // this is a public method to get the teams
    // it fetches the teams from the API if they are not already fetched
    // or if the data is stale (the last fetch was more than 10 seconds ago)
    public teams(): Team[] {
        if (this.teamInfo.length > 0) {
            // Return cached team data immediately
            return this.teamInfo;
        }

        if (!this.isFetching) {
            // Fetch teams from API and update cache
            this.fetchTeams();
        }

        // Return empty team data until fetch completes
        return [];
    }

    private createEmblemElement(emblem: string, emblemUrl: string): React.ReactNode {
        if (emblemUrl) {
            return <img src={emblemUrl} alt="Team Emblem" className="w-full h-full object-contain" />;
        }
        return <span>{emblem}</span>;
    }
}
