import React from 'react';

interface FormFieldProps {
  label: string;
  icon?: React.ReactNode;
  error?: string;
  children: React.ReactNode;
}

const FormField: React.FC<FormFieldProps> = ({ label, icon, error, children }) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
    <div
      className={`relative flex items-center border ${
        error ? 'border-red-500' : 'border-gray-300'
      } rounded-md focus-within:ring-2 focus-within:ring-primary`}
    >
      {icon && <div className="pl-3">{icon}</div>}
      {React.cloneElement(children as React.ReactElement, {
        className: `w-full p-2 focus:outline-none rounded-md text-base font-medium text-gray-800 font-sans placeholder-gray-400 ${
          error ? 'border-red-500' : ''
        }`,
      })}
    </div>
    {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
  </div>
);

export default FormField;