import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Turnstile from '../components/Turnstile';
import RedirectIfAuthenticated from '../components/RedirectIfAuthenticated';

const SignIn: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [turnstileToken, setTurnstileToken] = useState('');
  const [turnstileResetKey, setTurnstileResetKey] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage('');

    if (!turnstileToken) {
      setErrorMessage('Please complete the Turnstile challenge.');
      return;
    }

    try {
      const response = await fetch('https://championships.kickoff.game/api/auth/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'cf-turnstile-response': turnstileToken,
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Failed to sign in.');
      }

      const data = await response.json();

      // Extract tokens from response body
      const { access_token: accessToken, refresh_token: refreshToken } = data;

      if (accessToken && refreshToken) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
      } else {
        throw new Error('Tokens not received from server.');
      }

      navigate('/dashboard');
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      // Reset the Turnstile token and widget
      setTurnstileToken('');
      setTurnstileResetKey((prevKey) => prevKey + 1);
    }
  };

  return (
    <div className="bg-gradient-to-br from-green-700 to-blue-800 text-white min-h-screen flex items-center justify-center">
      <RedirectIfAuthenticated />

      <div className="bg-white text-blue-900 p-8 rounded-lg shadow-lg max-w-md w-full">
        <h1 className="text-3xl font-bold mb-6 text-center">Welcome to Kickoff Championship</h1>
        <p className="text-center mb-8">Sign in to participate in the tournament</p>

        {errorMessage && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
            role="alert"
          >
            <span className="block sm:inline">{errorMessage}</span>
          </div>
        )}

        <form onSubmit={handleSignIn} className="mb-6">
          <div className="mb-4">
            <label htmlFor="username" className="block text-sm font-medium text-gray-700">
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>

          <div className="mb-6">
            <Turnstile
              siteKey="0x4AAAAAAAjuZz4sYx8SxbrU"
              onTokenChange={setTurnstileToken}
              resetKey={turnstileResetKey}
            />
          </div>

          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-full mb-4"
          >
            Sign In
          </button>
        </form>

        <div className="text-center mb-6">
          <p className="text-sm">
            Don't have an account?{' '}
            <a
              href="/signup"
              onClick={(e) => {
                e.preventDefault();
                navigate('/signup');
              }}
              className="text-blue-500 hover:underline font-semibold"
            >
              Sign Up
            </a>
          </p>
        </div>

        <div className="text-center text-sm">
          By signing in, you agree to our
          <a
            href="/terms"
            onClick={(e) => {
              e.preventDefault();
              navigate('/terms');
            }}
            className="text-blue-500 hover:underline"
          >
            {' '}
            Terms and Conditions
          </a>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
