import React, { useState } from 'react';
import { User } from '../models/user';

interface CoverPhotoProps {
  user: User;
  onUpdate: (updatedFields: Partial<User>) => Promise<void>;
}

const CoverPhoto: React.FC<CoverPhotoProps> = ({ user, onUpdate }) => {
  const [coverPhotoPreview, setCoverPhotoPreview] = useState<string>(
    user.cover_picture_url || 'https://imagedelivery.net/60EBju9pR70EGeFkQ4RoMw/a2b87806-07d0-495f-1ed5-a7bfd0c22200/public' // Replace with your default URL
  );
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const handleCoverPhotoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setIsUpdating(true);
      const file = e.target.files[0];
      const coverPhotoUrl = URL.createObjectURL(file);
      setCoverPhotoPreview(coverPhotoUrl);

      try {
        const uploadedUrl = await user.uploadUserImage(file); // Implement this method
        await onUpdate({ cover_picture_url: uploadedUrl });
      } catch (error) {
        console.error('Failed to upload cover photo:', error);
      } finally {
        setIsUpdating(false);
      }
    }
  };

  return (
    <div className="relative">
      <img
        src={coverPhotoPreview}
        alt="Cover"
        className="w-full h-48 object-cover" // Adjust height as needed
      />
      <label
        htmlFor="coverPhotoInput"
        className="absolute bottom-2 right-2 bg-gray-800 bg-opacity-75 text-white p-2 rounded cursor-pointer"
      >
        {isUpdating ? 'Updating...' : 'Change Cover'}
      </label>
      <input
        type="file"
        id="coverPhotoInput"
        accept="image/*"
        className="hidden"
        onChange={handleCoverPhotoChange}
        disabled={isUpdating}
      />
    </div>
  );
};

export default CoverPhoto;