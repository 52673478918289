import React from 'react';

const Loading: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-white animate-fade-in">
      <div className="relative">
        <div className="w-16 h-16 border-4 border-indigo-200 rounded-full"></div>
        <div className="absolute top-0 left-0 w-16 h-16 border-4 border-indigo-500 border-t-transparent border-b-transparent rounded-full animate-spin"></div>
      </div>
    </div>
  );
};

export default Loading;