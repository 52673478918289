import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { User } from '../models/user';

interface CompleteProfileNoticeProps {
  user: User; // Add user prop
}

const CompleteProfileNotice: React.FC<CompleteProfileNoticeProps> = ({
  user,
}) => {
  if (user.isComplete()) {
    return null; // Hide the notice if profile is complete
  }

  return (
    <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-6 flex items-center">
      <FaInfoCircle className="text-blue-500 mr-3 flex-shrink-0" size={20} />
      <div>
        <p className="font-semibold">
          Complete your profile to get the most out of Kickoff!
        </p>
        <p className="mt-2 text-sm">
          Fill in your personal information and privacy settings below to unlock all features.
        </p>
      </div>
    </div>
  );
};

export default CompleteProfileNotice;
