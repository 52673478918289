import React, { useState, useEffect } from 'react';
import { User } from '../models/user';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../utils/cropImage';
import { FaUser, FaRegCalendarAlt, FaMapMarkerAlt, FaInfoCircle } from 'react-icons/fa'; // Added FaInfoCircle
import LoadingButton from './LoadingButton';
import FormField from './FormField'; // Import the new FormField component

interface PersonalInfoProps {
  user: User;
  onUpdate: (updatedFields: Partial<User>) => Promise<void>;
}

const PersonalInfo: React.FC<PersonalInfoProps> = ({ user, onUpdate }) => {
  const [firstName, setFirstName] = useState(user.first_name || '');
  const [lastName, setLastName] = useState(user.last_name || '');
  const [bio, setBio] = useState(user.bio || '');
  const [birthDate, setBirthDate] = useState(() => {
    if (user.birth_date) {
      const date = new Date(user.birth_date);
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
    }
    return '';
  });
  const [location, setLocation] = useState(user.location || '');
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [profilePicturePreview, setProfilePicturePreview] = useState<string>(
    user.profile_picture_url || ''
  );
  const [dateError, setDateError] = useState('');

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState<string>(null);
  const [showCropper, setShowCropper] = useState<boolean>(false);

  const [isImageAvailable, setIsImageAvailable] = useState<boolean>(!!user.profile_picture_url);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateStatus, setUpdateStatus] = useState<'success' | 'error' | null>(null);

  useEffect(() => {
    if (user.profile_picture_url) {
      fetch(user.profile_picture_url)
        .then(response => {
          if (!response.ok) {
            setIsImageAvailable(false);
          }
        })
        .catch(() => {
          setIsImageAvailable(false);
        });
    }
  }, [user.profile_picture_url]);

  const handleSave = async () => {
    if (!isValidDate(birthDate)) {
      setDateError('Invalid date format. Please use mm/dd/yyyy.');
      return;
    }

    setDateError('');
    setIsUpdating(true);
    setUpdateStatus(null);

    try {
      const updatedFields: Partial<User> = {
        first_name: firstName,
        last_name: lastName,
        bio: bio,
        birth_date: birthDate ? new Date(birthDate) : null,
        location: location,
      };

      if (croppedImage) {
        const blob = await fetch(croppedImage).then(r => r.blob());
        // Autodetect image type
        const imageType = blob.type || 'image/jpeg'; 
        const fileExtension = imageType.split('/')[1];
        const file = new File([blob], `${user.user_id}.${fileExtension}`, { type: imageType });
        const profilePictureUrl = await user.uploadUserImage(file);
        updatedFields.profile_picture_url = profilePictureUrl;
      }

      await onUpdate(updatedFields);
      setUpdateStatus('success');
    } catch (error) {
      console.error('Failed to update personal information:', error);
      setUpdateStatus('error');
    } finally {
      setIsUpdating(false);
      setTimeout(() => setUpdateStatus(null), 3000); // Clear status after 3 seconds
    }
  };

  const isValidDate = (date: string): boolean => {
    if (!date) return true; // Allow empty date
    const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;
    return regex.test(date) && !isNaN(new Date(date).getTime());
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBirthDate(e.target.value);
    setDateError('');
  };

  const handleProfilePictureChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setProfilePicture(file);
      setProfilePicturePreview(URL.createObjectURL(file));
      setShowCropper(true);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCropSave = async () => {
    try {
      const croppedImage = await getCroppedImg(profilePicturePreview, croppedAreaPixels);
      setCroppedImage(croppedImage);
      setProfilePicturePreview(croppedImage);
      setShowCropper(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleCropCancel = () => {
    setShowCropper(false);
    setProfilePicture(null);
    setProfilePicturePreview(user.profile_picture_url || '/default-avatar.png');
  };

  return (
    <div className="col-span-1">
      <h2 className="text-xl font-semibold mb-4 text-gray-800">Personal Information</h2>
      <div className="space-y-4">
        {/* Profile Picture Upload */}
        <div>
          {/* <label className="block text-gray-700 mb-2">Profile Picture</label> */}
          <div className="flex items-center">
            <div className="relative">
              {(isImageAvailable && !profilePicture) ? (
                <img
                  src={user.profile_picture_url}
                  alt="Profile"
                  className="h-24 w-24 rounded-full object-cover border-2 border-gray-300"
                />
              ) : profilePicturePreview ? (
                <img
                  src={profilePicturePreview}
                  alt="Profile"
                  className="h-24 w-24 rounded-full object-cover border-2 border-gray-300"
                />
              ) : (
                <div className="h-24 w-24 rounded-full bg-gray-200 flex items-center justify-center">
                  <FaUser className="text-gray-400 text-4xl" />
                </div>
              )}
              <label
                htmlFor="profilePictureInput"
                className="absolute bottom-0 right-0 bg-indigo-600 hover:bg-indigo-700 text-white rounded-full p-2 cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15.232 5.232l3.536 3.536M16 2a2 2 0 012 2v4M21 16v4a2 2 0 01-2 2h-4M8 21H4a2 2 0 01-2-2v-4M3 8V4a2 2 0 012-2h4"
                  />
                </svg>
              </label>
              <input
                type="file"
                id="profilePictureInput"
                accept="image/*"
                className="hidden"
                onChange={handleProfilePictureChange}
              />
            </div>
          </div>
        </div>

        {/* Cropper Modal */}
        {showCropper && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-4 rounded-lg shadow-lg">
              <h3 className="text-lg font-medium text-gray-800 mb-4">Adjust Profile Picture</h3>
              <div className="relative w-64 h-64 bg-gray-200">
                <Cropper
                  image={profilePicturePreview}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                />
              </div>
              <div className="flex justify-end mt-4 space-x-2">
                <button
                  onClick={handleCropCancel}
                  className="px-4 py-2 bg-gray-500 text-white rounded-sm"
                >
                  Cancel
                </button>
                <button
                  onClick={handleCropSave}
                  className="px-4 py-2 bg-indigo-600 text-white rounded-sm"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}

        {/* First Name */}
        <FormField label="First Name" icon={<FaUser className="text-gray-400" />}>
          <input
            type="text"
            className="w-64 p-2 focus:outline-none rounded-md"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </FormField>

        {/* Last Name */}
        <FormField label="Last Name" icon={<FaUser className="text-gray-400" />}>
          <input
            type="text"
            className="w-64 p-2 focus:outline-none rounded-md"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </FormField>

        {/* Date of Birth */}
        <FormField
          label="Date of Birth"
          icon={<FaRegCalendarAlt className="text-gray-400" />}
          error={dateError}
        >
          <input
            type="text"
            placeholder="mm/dd/yyyy"
            className="w-64 p-2 focus:outline-none rounded-md"
            value={birthDate}
            onChange={handleDateChange}
          />
        </FormField>

        {/* Location */}
        <FormField label="Location" icon={<FaMapMarkerAlt className="text-gray-400" />}>
          <input
            type="text"
            className="w-64 p-2 focus:outline-none rounded-md"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </FormField>

        {/* About Me */}
        <FormField label="About Me" icon={<FaInfoCircle className="text-gray-400" />}>
          <textarea
            className="w-64 p-2 focus:outline-none rounded-md"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            rows={4}
          />
        </FormField>

        {/* Save Button and Status */}
        <div className="mt-4 flex items-center">
          <LoadingButton
            isLoading={isUpdating}
            onClick={handleSave}
            aria-label="Saving..."
          >
            Save Changes
          </LoadingButton>
        </div>

        {/* Place the status message directly below the Save Changes button */}
        {updateStatus === 'success' && (
          <p className="mt-2 text-green-600">Changes saved successfully!</p>
        )}

        {updateStatus === 'error' && (
          <p className="mt-2 text-red-600">Failed to save changes. Please try again.</p>
        )}
      </div>
    </div>
  );
};

export default PersonalInfo;
