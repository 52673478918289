import React, { useEffect, useState } from 'react';
import { User } from '../models/user';
import { Payment } from '../models/payment';

interface PaymentHistoryProps {
  user: User;
}

const PaymentHistory: React.FC<PaymentHistoryProps> = ({ user }) => {
  const [payments, setPayments] = useState<Payment[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    fetchPayments();
  }, []);

  const fetchPayments = async () => {
    setIsLoading(true);
    try {
      const paymentsData = await Payment.fetchUserPayments(user.user_id);
      setPayments(paymentsData);
    } catch (error) {
      console.error('Failed to fetch payments:', error);
      setError('Failed to load payments. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <p>Loading payment history...</p>;
  }

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  return (
    <div className="col-span-1 md:col-span-2">
      <h2 className="text-xl font-semibold mb-4 text-gray-800">Payment History</h2>
      <table className="min-w-full bg-white rounded-md shadow overflow-hidden">
        <thead className="bg-gray-100">
          <tr>
            <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">Date</th>
            <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">Amount</th>
            <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">Status</th>
            <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">Entity</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {payments.map((payment) => (
            <tr key={payment.payment_id}>
              <td className="py-2 px-4 text-sm text-gray-600">
                {new Date(payment.paid_at).toLocaleDateString()}
              </td>
              <td className="py-2 px-4 text-sm text-gray-600">${payment.amount.toFixed(2)}</td>
              <td className="py-2 px-4 text-sm text-gray-600 capitalize">{payment.status}</td>
              <td className="py-2 px-4 text-sm text-gray-600">{payment.entity_type}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentHistory;