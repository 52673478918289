import React from 'react';
import {
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaUsers,
  FaHome,
} from 'react-icons/fa';

interface BottomNavBarProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const BottomNavBar: React.FC<BottomNavBarProps> = ({
  activeTab,
  setActiveTab,
}) => {
  return (
    <div className="md:hidden fixed bottom-0 left-0 right-0 bg-white shadow-md">
      <div className="flex justify-around py-2">
        <button
          className={`flex flex-col items-center ${
            activeTab === 'forYou' ? 'text-indigo-600' : 'text-gray-500'
          }`}
          onClick={() => setActiveTab('forYou')}
        >
          <FaHome size={24} />
          <span className="text-xs">For You</span>
        </button>
        <button
          className={`flex flex-col items-center ${
            activeTab === 'upcoming' ? 'text-indigo-600' : 'text-gray-500'
          }`}
          onClick={() => setActiveTab('upcoming')}
        >
          <FaCalendarAlt size={24} />
          <span className="text-xs">Upcoming</span>
        </button>
        <button
          className={`flex flex-col items-center ${
            activeTab === 'nearby' ? 'text-indigo-600' : 'text-gray-500'
          }`}
          onClick={() => setActiveTab('nearby')}
        >
          <FaMapMarkerAlt size={24} />
          <span className="text-xs">Nearby</span>
        </button>
        <button
          className={`flex flex-col items-center ${
            activeTab === 'friends' ? 'text-indigo-600' : 'text-gray-500'
          }`}
          onClick={() => setActiveTab('friends')}
        >
          <FaUsers size={24} />
          <span className="text-xs">Friends</span>
        </button>
      </div>
    </div>
  );
};

export default BottomNavBar;
