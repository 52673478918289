import React from 'react';
import GameCard from '../components/GameCard';

interface Game {
  id: number;
  date: string;
  time: string;
  teams: string;
  tournament?: string;
  location?: string;
  type: string; // 'upcoming' or 'nearby'
}

interface GamesListProps {
  games: Game[];
}

const GamesList: React.FC<GamesListProps> = ({ games }) => {
  return (
    <div className="space-y-4">
      {games.map((game) => (
        <GameCard key={game.id} game={game} />
      ))}
    </div>
  );
};

export default GamesList;
