export interface Payment {
  payment_id: string;
  entity_id: string;
  entity_type: string;
  participant_id: string;
  amount: number;
  paid_at: string;
  status: string;
}

export class Payment {
  private static paymentCache: Payment[] | null = null;

  static async fetchUserPayments(userId: string): Promise<Payment[]> {
    if (this.paymentCache) {
      // Return cached payments immediately
      return this.paymentCache;
    }

    // Make the network call
    const response = await fetch(`/api/payments?user_id=${userId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch payments');
    }
    const data = await response.json();
    // Update cache with new data
    this.paymentCache = data as Payment[];
    return this.paymentCache;
  }
}