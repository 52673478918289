import React from 'react';
import { FaFutbol, FaMapMarkerAlt } from 'react-icons/fa';

interface Game {
  id: number;
  date: string;
  time: string;
  teams: string;
  tournament?: string;
  location?: string;
  type: string; // 'upcoming' or 'nearby'
}

interface GameCardProps {
  game: Game;
}

const GameCard: React.FC<GameCardProps> = ({ game }) => {
  const date = new Date(game.date);
  const dateBoxColor =
    game.type === 'upcoming' ? 'bg-indigo-600' : 'bg-green-600';

  return (
    <div className="flex bg-white shadow-md rounded-lg overflow-hidden">
      <div
        className={`flex-shrink-0 ${dateBoxColor} text-white flex flex-col items-center justify-center px-4 py-2`}
      >
        <div className="text-xl font-bold">{date.getDate()}</div>
        <div className="text-sm">
          {date.toLocaleString('default', { month: 'short' })}
        </div>
      </div>
      <div className="p-4 flex flex-col justify-between flex-grow">
        <div>
          <div className="font-semibold text-gray-800">{game.teams}</div>
          <div className="text-sm text-gray-600">{game.time}</div>
        </div>
        {game.tournament && (
          <div className="text-sm text-indigo-600 mt-2">
            <FaFutbol className="inline mr-1" />
            {game.tournament}
          </div>
        )}
        {game.location && (
          <div className="text-sm text-green-600 mt-2">
            <FaMapMarkerAlt className="inline mr-1" />
            {game.location}
          </div>
        )}
      </div>
    </div>
  );
};

export default GameCard;
