// Standings.tsx
import React from 'react';
import { TeamsDataAccessor, Team } from '../models/teams';

interface StandingsProps {}

// get the teams from the data accessor
const teamsDataAccessor = new TeamsDataAccessor();
const teams = teamsDataAccessor.teams();

const Standings: React.FC<StandingsProps> = () => {
    return (
        <div className="w-full max-w-full bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="bg-gradient-to-r from-blue-600 to-blue-800 p-6 w-full">
                <h3 className="text-2xl font-semibold text-white opacity-90">Standings</h3>
            </div>
            <div className="overflow-x-auto">
                <table className="w-full">
                    <thead>
                        <tr className="bg-gray-100 text-gray-600 text-sm uppercase">
                            <th className="py-3 px-4 text-left">Team</th>
                            <th className="py-3 px-2 text-center">P</th>
                            <th className="py-3 px-2 text-center">W</th>
                            <th className="py-3 px-2 text-center">D</th>
                            <th className="py-3 px-2 text-center">L</th>
                            <th className="py-3 px-2 text-center">GF</th>
                            <th className="py-3 px-2 text-center">GA</th>
                            <th className="py-3 px-2 text-center">GD</th>
                            <th className="py-3 px-2 text-center">Pts</th>
                        </tr>
                    </thead>
                    <tbody>
                        {teams.map((team: Team) => (
                            team.id === 0 ? null : (
                                <tr key={team.id} className="border-b border-gray-200 hover:bg-gray-50">
                                    <td className="py-4 px-4 flex items-center space-x-2">
                                        {team.emblemElement()}
                                        <span className="font-medium ml-2">{team.name}</span>
                                    </td>
                                    <td className="py-4 px-2 text-center">{team.played}</td>
                                    <td className="py-4 px-2 text-center">{team.won}</td>
                                    <td className="py-4 px-2 text-center">{team.drawn}</td>
                                    <td className="py-4 px-2 text-center">{team.lost}</td>
                                    <td className="py-4 px-2 text-center">{team.goalsFor}</td>
                                    <td className="py-4 px-2 text-center">{team.goalsAgainst}</td>
                                    <td className="py-4 px-2 text-center">{team.goalsFor - team.goalsAgainst}</td>
                                    <td className="py-4 px-2 text-center font-bold">{team.points}</td>
                                </tr>
                            )
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Standings;