import React, { useState, useRef, useEffect } from 'react';
import {
  // FaComments,
  // FaBell,
  // FaCog,
  FaUserCircle,
  // FaPlusCircle,
  // FaSearch,
  FaUserEdit,
  FaSignOutAlt,
  FaTimes,
} from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';
import { User } from '../models/user';
import RedirectIfAuthenticated from './RedirectIfAuthenticated';

const TopNavBar: React.FC = () => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMobileSettings, setShowMobileSettings] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const mobileSettingsRef = useRef<HTMLDivElement>(null);

  const handleSignOut = async () => {
    try {
      const currentUser = await User.fetchCurrentUser();
      await currentUser.signOut();
    } catch (error) {
      console.error('Sign out failed', error);
    }
    navigate('/signin');
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const closeMobileSettings = () => {
    setShowMobileSettings(false);
  };

  return (
    <div className="bg-white shadow-md py-3 px-4 md:px-6 flex items-center justify-between relative">
      <RedirectIfAuthenticated />
      <Link to="/dashboard" className="flex items-center cursor-pointer">
        <img
          src="https://imagedelivery.net/60EBju9pR70EGeFkQ4RoMw/2af30e91-2bd6-4d9b-fb3c-b0b9d8bac800/240x240"
          alt="Kickoff Logo"
          className="h-8 w-auto mr-2 md:mr-4"
        />
        <h1 className="text-xl font-bold text-gray-800">Kickoff</h1>
      </Link>
      {/* <div className="flex-grow mx-4 md:mx-10 flex items-center">
        <div className="relative flex-grow mr-2 md:mr-4">
          <input
            type="text"
            placeholder="Search..."
            className="w-full bg-gray-200 rounded-full py-2 px-4 pl-10 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-gray-700 placeholder-gray-500 shadow-sm"
          />
          <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
        </div>
        <button className="hidden md:flex items-center space-x-2 bg-rose-500 hover:bg-rose-600 text-white px-4 md:px-6 py-2 rounded-full transition duration-300 transform hover:scale-105 shadow-lg">
          <FaPlusCircle size={20} />
          <span className="font-semibold">New Game</span>
        </button>
      </div> */}

      {/* Desktop Menu */}
      <div className="hidden md:flex items-center space-x-4">
        {/* <button className="text-gray-500 hover:text-gray-700">
          <FaComments size={20} />
        </button>
        <button className="text-gray-500 hover:text-gray-700">
          <FaBell size={20} />
        </button> */}
        {/* Commented out cog icon
        <div className="relative" ref={dropdownRef}>
          <button
            className="text-gray-500 hover:text-gray-700 transition duration-300 p-2 flex items-center justify-center"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <FaCog size={20} />
          </button>
          ... (dropdown menu code) ...
        </div>
        */}
        <div className="relative" ref={dropdownRef}>
          <button
            className="flex items-center space-x-2 bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded-full transition duration-300"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <FaUserCircle size={20} />
            <span className="align-middle">Profile</span>
          </button>
          {showDropdown && (
            <div
              className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-50 overflow-hidden"
              style={{ top: '100%' }}
            >
              {/* <Link
                to="/profile"
                className="flex items-center px-4 py-3 text-gray-700 hover:bg-indigo-50 transition duration-300"
              >
                <FaUserCircle className="mr-3 text-indigo-600" size={18} />
                <span className="align-middle">View Profile</span>
              </Link> */}
              <Link
                to="/editprofile"
                className="flex items-center px-4 py-3 text-gray-700 hover:bg-indigo-50 transition duration-300"
              >
                <FaUserEdit className="mr-3 text-indigo-600" size={18} />
                <span className="align-middle">Edit Profile</span>
              </Link>
              <button
                onClick={handleSignOut}
                className="w-full flex items-center px-4 py-3 text-gray-700 hover:bg-rose-50 transition duration-300"
              >
                <FaSignOutAlt className="mr-3 text-rose-600" size={18} />
                <span className="align-middle">Sign Out</span>
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Mobile Menu Button */}
      <div className="md:hidden">
        <button
          onClick={() => setShowMobileSettings(true)}
          className="flex items-center space-x-2 bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded-full transition duration-300"
        >
          <FaUserCircle size={20} />
          <span className="align-middle">Profile</span>
        </button>
      </div>

      {/* Mobile Settings Modal */}
      {showMobileSettings && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-end md:hidden z-50">
          <div 
            className="absolute inset-0"
            onClick={closeMobileSettings}
          ></div>
          <div 
            ref={mobileSettingsRef}
            className="bg-white rounded-t-lg w-full p-4 animate-slide-up relative"
          >
            <h2 className="text-lg font-semibold text-gray-800 mb-4 text-center">Settings</h2>
            <div className="space-y-4">
              <button
                onClick={() => {
                  navigate('/profile');
                  closeMobileSettings();
                }}
                className="w-full flex items-center justify-center px-4 py-3 text-gray-700 hover:bg-indigo-50 transition duration-300 rounded"
              >
                <FaUserCircle className="mr-3 text-indigo-600" size={20} />
                <span className="align-middle">Profile</span>
              </button>
              <button
                onClick={() => {
                  navigate('/editprofile');
                  closeMobileSettings();
                }}
                className="w-full flex items-center justify-center px-4 py-3 text-gray-700 hover:bg-indigo-50 transition duration-300 rounded"
              >
                <FaUserEdit className="mr-3 text-indigo-600" size={20} />
                <span className="align-middle">Edit Profile</span>
              </button>
              <button
                onClick={() => {
                  handleSignOut();
                  closeMobileSettings();
                }}
                className="w-full flex items-center justify-center px-4 py-3 text-gray-700 hover:bg-rose-50 transition duration-300 rounded"
              >
                <FaSignOutAlt className="mr-3 text-rose-600" size={20} />
                <span className="align-middle">Sign Out</span>
              </button>
              <button
                onClick={closeMobileSettings}
                className="w-full flex items-center justify-center px-4 py-3 text-gray-700 hover:bg-gray-200 transition duration-300 rounded"
              >
                <FaTimes className="mr-3 text-gray-600" size={20} />
                <span className="align-middle">Cancel</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopNavBar;
