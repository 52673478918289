import React from 'react';
import { TeamsDataAccessor, Team } from '../models/teams';

interface Match {
  id: number;
  homeTeamId: number;
  awayTeamId: number;
  date: string;
  time: string;
}

interface TournamentScheduleProps {
  fixtures: Match[];
}

const TeamEmblem: React.FC<{ team: Team | undefined }> = ({ team }) => (
  <div className="team-emblem w-8 h-8 bg-gray-100 rounded-full flex items-center justify-center cursor-pointer">
    {team?.emblemElement()}
  </div>
);

const TournamentSchedule: React.FC<TournamentScheduleProps> = ({ fixtures }) => {
  const teamsDataAccessor = new TeamsDataAccessor();
  const teams = teamsDataAccessor.teams();

  return (
    <div className="tournament-schedule bg-white rounded-lg p-6">
        <h3 className="text-2xl font-bold mb-6 text-center">Tournament Schedule</h3>
        
        {/* Group Stage */}
        <div className="mb-8">
            <h4 className="text-xl font-semibold mb-4">Group Stage</h4>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Group A */}
                <div>
                    <h5 className="text-lg font-semibold mb-3">Group A</h5>
                    <div className="space-y-4">
                        {fixtures.filter(match => match.id <= 3).map(match => (
                            <div key={match.id} className="flex items-center">
                                <span className="w-20 text-right pr-4 text-sm font-medium">{match.time}</span>
                                <div className="flex-grow h-px bg-gray-300"></div>
                                <TeamEmblem team={teams.find(team => team.id === match.homeTeamId)} />
                                <span className="text-sm font-bold mx-2">vs</span>
                                <TeamEmblem team={teams.find(team => team.id === match.awayTeamId)} />
                                <div className="flex-grow h-px bg-gray-300"></div>
                            </div>
                        ))}
                    </div>
                </div>
                
                {/* Group B */}
                <div>
                    <h5 className="text-lg font-semibold mb-3">Group B</h5>
                    <div className="space-y-4">
                        {fixtures.filter(match => match.id > 3 && match.id <= 6).map(match => (
                            <div key={match.id} className="flex items-center">
                                <span className="w-20 text-right pr-4 text-sm font-medium">{match.time}</span>
                                <div className="flex-grow h-px bg-gray-300"></div>
                                <TeamEmblem team={teams.find(team => team.id === match.homeTeamId)} />
                                <span className="text-sm font-bold mx-2">vs</span>
                                <TeamEmblem team={teams.find(team => team.id === match.awayTeamId)} />
                                <div className="flex-grow h-px bg-gray-300"></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>

        {/* Break */}
        <div className="mb-8">
            <h4 className="text-xl font-semibold mb-4">Break</h4>
            <div className="flex items-center">
                <span className="w-20 text-right pr-4 text-sm font-medium">12:30 PM</span>
                <div className="flex-grow h-px bg-gray-300"></div>
                <span className="px-4 text-sm font-medium">Lunch Break (90 minutes)</span>
                <div className="flex-grow h-px bg-gray-300"></div>
            </div>
        </div>

        {/* Knockout Stage */}
        <div>
            <h4 className="text-xl font-semibold mb-4">Knockout Stage</h4>
            <div className="space-y-6">
                {fixtures.filter(match => match.id > 6).map(match => (
                    <div key={match.id} className="flex items-center">
                        <span className="w-20 text-right pr-4 text-sm font-medium">{match.time}</span>
                        <div className="flex-grow h-px bg-gray-300"></div>
                        <div className="flex items-center justify-center w-40">
                            <TeamEmblem team={teams.find(team => team.id === match.homeTeamId)} />
                            <span className="text-sm font-bold mx-4">vs</span>
                            <TeamEmblem team={teams.find(team => team.id === match.awayTeamId)} />
                        </div>
                        <div className="flex-grow h-px bg-gray-300"></div>
                        <span className="pl-4 text-sm font-medium w-32">
                            {match.id <= 8 ? 'Semi-Final' : 
                             match.id === 9 ? '3rd Place' : 'Finals'}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    </div>
  );
};

export default TournamentSchedule;