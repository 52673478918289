import React, { useState, useEffect } from 'react';
import TopNavBar from '../components/TopNavBar';
import UserProfile from '../components/UserProfileCard';
import Memberships from '../components/Memberships';
import FriendsList from '../components/FriendsList';
import GamesList from '../components/GamesList';
import BottomNavBar from '../components/BottomNavBar';
import FloatingActionButtons from '../components/FloatingActionButtons';

// Import the API function to fetch user data
import { User } from '../models/user';

const Dashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState('forYou');
  const [filter, setFilter] = useState('all'); // 'all', 'upcoming', 'nearby'
  const [userProfile, setUserProfile] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadUserProfile = async () => {
      try {
        const userData = await User.fetchCurrentUser();
        setUserProfile(userData);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadUserProfile();
  }, []);

  // Dummy data (same as before)
  const memberships = {
    teams: ['Thunderbolts FC', 'City United'],
    clubs: ['Soccer Enthusiasts', 'Downtown Sports Club'],
    tournaments: ['Summer League 2023', 'City Cup 2023'],
  };

  const friends = [
    { id: 1, name: 'Alice Smith', status: 'online' },
    { id: 2, name: 'Bob Johnson', status: 'offline' },
    { id: 3, name: 'Charlie Brown', status: 'online' },
    // Add more friends as needed
  ];

  // Merged and sorted games list
  const gamesData = [
    {
      id: 1,
      date: '2023-06-15',
      time: '14:00',
      teams: 'Thunderbolts FC vs City Rovers',
      tournament: 'Summer League',
      type: 'upcoming',
    },
    {
      id: 2,
      date: '2023-06-16',
      time: '15:00',
      teams: 'Local FC vs City United',
      location: 'Central Park',
      type: 'nearby',
    },
    {
      id: 3,
      date: '2023-06-18',
      time: '16:30',
      teams: 'City United vs Suburb Strikers',
      tournament: 'City Cup',
      type: 'upcoming',
    },
    {
      id: 4,
      date: '2023-06-19',
      time: '18:00',
      teams: 'Suburb Strikers vs Downtown Dynamos',
      location: 'Community Field',
      type: 'nearby',
    },
    // Add more games as needed
  ].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  // Filtered games based on the selected filter
  const filteredGames = gamesData.filter((game) => {
    if (filter === 'all') return true;
    return game.type === filter;
  });

  return (
    <div className="min-h-screen bg-gray-50 relative">
      {/* Top Navigation Bar */}
      <TopNavBar />

      {/* Main Content */}
      <div className="container mx-auto px-4 py-8">
        <div className="md:flex md:gap-8">
          {/* Left Column */}
          <div className="md:w-1/3 lg:w-1/4 hidden md:block overflow-y-auto max-h-screen">
            {isLoading ? (
              <div>Loading user profile...</div>
            ) : (
              <UserProfile user={userProfile} isLoading={isLoading} />
            )}
            {/* <Memberships memberships={memberships} />
            <FriendsList friends={friends} /> */}
          </div>

          {/* Right Column */}
          <div className="md:w-2/3 lg:w-3/4">
            {/* Tabs for Mobile */}
            <div className="md:hidden mb-4">
              <div className="flex justify-around border-b py-2 bg-white shadow-sm">
                <button
                  className={`flex-1 text-center ${
                    activeTab === 'forYou' ? 'text-indigo-600' : 'text-gray-500'
                  }`}
                  onClick={() => setActiveTab('forYou')}
                >
                  <span className="text-sm font-semibold">For You</span>
                </button>
                <button
                  className={`flex-1 text-center ${
                    activeTab === 'upcoming'
                      ? 'text-indigo-600'
                      : 'text-gray-500'
                  }`}
                  onClick={() => setActiveTab('upcoming')}
                >
                  <span className="text-sm font-semibold">Upcoming</span>
                </button>
                <button
                  className={`flex-1 text-center ${
                    activeTab === 'nearby' ? 'text-indigo-600' : 'text-gray-500'
                  }`}
                  onClick={() => setActiveTab('nearby')}
                >
                  <span className="text-sm font-semibold">Nearby</span>
                </button>
              </div>
            </div>

            {/* Filter Options for Desktop */}
            <div className="hidden md:flex justify-end mb-4">
              <div className="space-x-4">
                <button
                  className={`px-4 py-2 rounded-full ${
                    filter === 'all'
                      ? 'bg-indigo-600 text-white'
                      : 'bg-gray-200 text-gray-700'
                  }`}
                  onClick={() => setFilter('all')}
                >
                  All Games
                </button>
                <button
                  className={`px-4 py-2 rounded-full ${
                    filter === 'upcoming'
                      ? 'bg-indigo-600 text-white'
                      : 'bg-gray-200 text-gray-700'
                  }`}
                  onClick={() => setFilter('upcoming')}
                >
                  Upcoming
                </button>
                <button
                  className={`px-4 py-2 rounded-full ${
                    filter === 'nearby'
                      ? 'bg-indigo-600 text-white'
                      : 'bg-gray-200 text-gray-700'
                  }`}
                  onClick={() => setFilter('nearby')}
                >
                  Nearby
                </button>
              </div>
            </div>

            {/* Games List */}
            <GamesList
              games={
                activeTab === 'forYou'
                  ? filteredGames
                  : filteredGames.filter((game) => game.type === activeTab)
              }
            />
          </div>
        </div>
      </div>

      {/* Bottom Navigation Bar for Mobile */}
      <BottomNavBar activeTab={activeTab} setActiveTab={setActiveTab} />

      {/* Floating Action Buttons */}
      <FloatingActionButtons />
    </div>
  );
};

export default Dashboard;
