import React, { useState, useEffect, useCallback } from 'react';
import TopNavBar from '../components/TopNavBar';
import EmailVerificationNotice from '../components/EmailVerificationNotice';
import CompleteProfileNotice from '../components/CompleteProfileNotice';
import PersonalInfo from '../components/PersonalInfo';
import AccountSettings from '../components/AccountSettings';
import SecuritySettings from '../components/SecuritySettings';
import PrivacySettings from '../components/PrivacySettings';
import { User } from '../models/user';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import Loading from '../components/Loading';
import CoverPhoto from '../components/CoverPhoto'; // Import the new CoverPhoto component
import PaymentHistory from '../components/PaymentHistory'; // Import the PaymentHistory component

const EditProfilePage: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);
  const [profileComplete, setProfileComplete] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();

  const fetchUserData = useCallback(async () => {
    try {
      const userData = await User.fetchCurrentUser();
      setUser(userData);
      setIsEmailVerified(userData.email_verified);
      setProfileComplete(userData.isComplete());
    } catch (err) {
      console.error(err);

      // if (err instanceof AxiosError && err.response?.status === 401) {
      //   // Tokens are invalid or refresh failed, user needs to sign in again
      //   navigate('/signin');
      // } else {
      //   setError('Failed to load user data. Please try again.');
      // }
    }
  }, [navigate]);

  useEffect(() => {
    fetchUserData();
    const intervalId = setInterval(fetchUserData, 30000);
    return () => clearInterval(intervalId);
  }, [fetchUserData]);

  const handleVerifyEmail = async () => {
    try {
      await user.sendVerificationEmail();
    } catch (error) {
      console.error('Failed to send verification email:', error);
    }
    await fetchUserData();
    return isEmailVerified;
  };

  const handleUpdateUserPrivacy = async (updatedFields: Partial<User>) => {
    try {
      const updatedUser = await user.updateUser(updatedFields);
      setUser(updatedUser);
    } catch (error) {
      console.error('Failed to update user:', error);
    }
  };

  const handleUpdateUserPassword = async (current_password: string, new_password: string) => {
    try {
      const updatedUser = await user.updatePassword(current_password, new_password);
      setUser(updatedUser);
    } catch (error) {
      console.error('Failed to update password:', error);
    }
  };

  const handleUpdateUserPersonal = async (updatedFields: Partial<User>) => {
    try {
      const updatedUser = await user.updateUser(updatedFields);
      setUser(updatedUser);
    } catch (error) {
      console.error('Failed to update user:', error);
    }
  };

  const handleUpdateCoverPhoto = async (updatedFields: Partial<User>) => {
    try {
      const updatedUser = await user.updateUser(updatedFields);
      setUser(updatedUser);
    } catch (error) {
      console.error('Failed to update cover photo:', error);
    }
  };

  if (error) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  if (!user) {
    return <Loading />;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <TopNavBar />

      {/* Add Cover Photo Component */}
      <CoverPhoto
        user={user}
        onUpdate={handleUpdateCoverPhoto}
      />

      <div className="container mx-auto px-4 py-8">
        {!isEmailVerified && (
          <EmailVerificationNotice
            email={user.email}
            isEmailVerified={isEmailVerified}
            onVerify={handleVerifyEmail}
          />
        )}
        {!profileComplete && <CompleteProfileNotice user={user}/>}

        <div className="bg-white rounded-lg shadow-lg p-6">
          {/* <h1 className="text-2xl font-semibold mb-6 text-gray-800">Account Settings</h1> */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <PersonalInfo user={user} onUpdate={handleUpdateUserPersonal} />
            <AccountSettings user={user} />
            <SecuritySettings user={user} onUpdate={handleUpdateUserPassword} />
            <PrivacySettings user={user} onUpdate={handleUpdateUserPrivacy} />
            <PaymentHistory user={user} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfilePage;
