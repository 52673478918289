import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { User } from '../models/user';

interface UserProfileCardProps {
  user: User | null;
  isLoading: boolean;
}

const UserProfileCard: React.FC<UserProfileCardProps> = ({ user, isLoading }) => {
  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow-lg mb-6 transition-all duration-300 hover:shadow-xl overflow-hidden animate-pulse">
        <div className="relative">
          <div className="w-full h-32 bg-gray-200" />
          <div className="absolute -bottom-8 left-6 w-16 h-16 rounded-full bg-gray-200 border-4 border-white" />
        </div>
        <div className="pt-10 px-6 pb-6 text-center">
          <div className="h-8 bg-gray-200 rounded w-3/4 mx-auto mb-2" />
          <div className="h-4 bg-gray-200 rounded w-1/2 mx-auto" />
        </div>
      </div>
    );
  }

  if (!user) {
    return null;
  }

  return (
    <div className="bg-white rounded-lg shadow-lg mb-6 transition-all duration-300 hover:shadow-xl overflow-hidden">
      <div className="relative">
        <img
          src={user.profile_picture_url || 'default-cover.jpg'}
          alt="Cover"
          className="w-full h-32 object-cover"
        />
        <div className="absolute -bottom-8 left-6">
          {user.profile_picture_url ? (
            <img
              src={user.profile_picture_url}
              alt={`${user.first_name} ${user.last_name}`}
              className="w-16 h-16 rounded-full object-cover border-4 border-white"
            />
          ) : (
            <FaUserCircle className="text-6xl text-indigo-600 bg-white rounded-full" />
          )}
        </div>
      </div>
      <div className="pt-10 px-6 pb-6 text-center">
        <h2 className="text-2xl font-bold text-gray-800">
          {user.first_name} {user.last_name}
        </h2>
        <p className="text-sm text-gray-500">@{user.username}</p>
      </div>
    </div>
  );
};

export default UserProfileCard;
