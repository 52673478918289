// api.ts

import axios from 'axios';

// Create an instance of axios
const api = axios.create({
  baseURL: 'https://championships.kickoff.game', // Replace with your API base URL
  withCredentials: false, // To send cookies with requests if needed
});

// Create a simple cache object
const responseCache = new Map<string, any>();

// Request interceptor to add access token to headers
// If access token is not present in local storage
// Just return 401 Unauthorized
api.interceptors.request.use(
  (config) => {
    // If access token is present, add it to the headers
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken && config.headers) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    } else {
      // If access token is not present, return 401 Unauthorized
      return Promise.reject(new Error('Unauthorized'));
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle token refresh and redirection
api.interceptors.response.use(
  (response) => {
    // Cache the response data using the request URL as the key
    if (response.config.url) {
      responseCache.set(response.config.url, response.data);
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken) {
        try {
          const accessToken = localStorage.getItem('accessToken');
          // Make a request to refresh the token
          const response = await api.post('/api/auth/refresh', null, {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'X-Refresh-Token': refreshToken,
            },
          });

          const { accessToken: newAccessToken, refreshToken: newRefreshToken } = response.data;
          localStorage.setItem('accessToken', newAccessToken);
          localStorage.setItem('refreshToken', newRefreshToken);

          // Update the Authorization header for future requests
          api.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;

          // Update the Authorization header for the original request
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

          // Retry the original request
          return api(originalRequest);
        } catch (err) {
          // If refresh token is invalid, remove tokens and redirect to sign in
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          const currentPath = window.location.pathname + window.location.search;
          localStorage.setItem('redirectAfterSignIn', currentPath);
          window.location.href = '/signin';
          return Promise.reject(err);
        }
      } else {
        // No refresh token available, redirect to sign in
        const currentPath = window.location.pathname + window.location.search;
        localStorage.setItem('redirectAfterSignIn', currentPath);
        window.location.href = '/signin';
        return Promise.reject(error);
      }
    }

    // For other errors, reject the promise
    return Promise.reject(error);
  }
);

// Function to get cached responses
export const getCachedResponse = (url: string): any | undefined => {
  return responseCache.get(url);
};

export default api;
