import React from 'react';
import {
  FaComments,
  FaBell,
  FaCog,
  FaUserCircle,
  FaPlusCircle,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';


const FloatingActionButtons: React.FC = () => {
    const navigate = useNavigate();

  return (
    <div className="md:hidden fixed bottom-20 right-4 flex flex-col items-center space-y-4">
      <button className="bg-rose-500 hover:bg-rose-600 text-white p-4 rounded-full shadow-lg transition duration-300">
        <FaPlusCircle size={24} />
      </button>
      <button className="bg-indigo-600 hover:bg-indigo-700 text-white p-4 rounded-full shadow-lg transition duration-300">
        <FaComments size={24} />
      </button>
      <button className="bg-yellow-500 hover:bg-yellow-600 text-white p-4 rounded-full shadow-lg transition duration-300">
        <FaBell size={24} />
      </button>
      <button className="bg-green-500 hover:bg-green-600 text-white p-4 rounded-full shadow-lg transition duration-300"
      onClick={() => navigate('/profile')}>
        <FaUserCircle size={24} />
      </button>
      <button className="bg-gray-500 hover:bg-gray-600 text-white p-4 rounded-full shadow-lg transition duration-300">
        <FaCog size={24} />
      </button>
    </div>
  );
};

export default FloatingActionButtons;
